"use client";
import React, { useState, useCallback } from "react";

import clsx from "clsx";

import styles from "./Faq.module.scss";

interface Question {
  id: number;
  title: string;
  body: React.ReactNode;
}

const questions = [
  {
    id: 1,
    title: "完全栄養食ってどんな食べもの？",
    body: (
      <>
        1日に必要な栄養素の1/3をすべてとれる食品です
        <sup className="sup">※</sup>
        。いそがしくて自炊ができないときでも、かんたんにおいしく栄養がとれます。
        <p className="text__s text__gray__dark mg__top__s">
          ※ 1食分（BASE BREADは2袋、BASE Cookiesは4袋、BASE FOOD Deliは1袋、BASE Pancake
          Mixは1袋と卵Mサイズ1つ、牛乳(成分無調整)100mlを使用して調理した場合）で、栄養素等表示基準値に基づき、脂質・飽和脂肪酸・炭水化物・ナトリウム以外のすべての栄養素で1日分の基準値の1/3以上を含む。
        </p>
      </>
    ),
  },
  {
    id: 2,
    title: "毎日3食食べなければいけない？",
    body: (
      <>
        毎食でも食べていただけますが、1日3食食べなくても大丈夫です。
        <br />
        目安として月20食、小学校の給食と同じ頻度をおすすめしています。バランスのよい食事を1日に1食とることで、栄養のベースづくりをサポートします。
      </>
    ),
  },
  {
    id: 3,
    title: "どうやって食べればいい？",
    body: (
      <>
        袋からとり出してそのまま食べられます。バターやチョコをぬったり、ハムやチーズを挟んだり、お好きなアレンジでおたのしみください。
        <br />
        惣菜パンのBASE BREAD
        カレーは、温めて食べるのがおすすめです。温めることでふんわりとした食感になり、おいしくお召しあがりいただけます。
      </>
    ),
  },
  {
    id: 4,
    title: "原材料には何が入っている？",
    body: (
      <>
        小麦全粒粉や大豆、昆布など、栄養豊富な食材を10種類以上ブレンドしてつくっています。
        <p className="text__s text__gray__dark mg__top__s">
          ※アレルギー表示に関しては、
          <a
            href="https://basefood.zendesk.com/hc/ja/search?utf8=%E2%9C%93&query=%E3%82%A2%E3%83%AC%E3%83%AB%E3%82%AE%E3%83%BC"
            target="_blank"
            className={styles.textYellow}
          >
            こちら
          </a>
          をご覧ください。
          <br />
          ※原材料に関しては、
          <a
            href="https://basefood.zendesk.com/hc/ja/search?utf8=%E2%9C%93&query=%E5%8E%9F%E6%9D%90%E6%96%99"
            target="_blank"
            className={styles.textYellow}
          >
            こちら
          </a>
          をご覧ください。
        </p>
      </>
    ),
  },
  {
    id: 5,
    title: "賞味期限はどのくらい？",
    body: (
      <>
        賞味期限は以下をご覧ください。
        <br />
        ・BASE BREADは
        <a
          href="https://basefood.zendesk.com/hc/ja/articles/360029507251"
          target="_blank"
          className={styles.textYellow}
        >
          こちら
        </a>
        <br />
        ・BASE Cookiesは
        <a
          href="https://basefood.zendesk.com/hc/ja/articles/6638687745561"
          target="_blank"
          className={styles.textYellow}
        >
          こちら
        </a>
        <br />
        常温で保存可能です。
      </>
    ),
  },
] as const satisfies Question[];

export function Faq(): React.ReactNode {
  const [selectedFaqIdList, setsSelectedFaqIdList] = useState<number[]>([]);

  const clickFaq = useCallback(
    (num: number) => {
      if (selectedFaqIdList.includes(num)) {
        setsSelectedFaqIdList(selectedFaqIdList.filter((item) => item !== num));
      } else {
        setsSelectedFaqIdList([...selectedFaqIdList, num]);
      }
    },
    [selectedFaqIdList]
  );

  return (
    <div className="container pd__top__xl pd__bottom__xl">
      <div className="row">
        <div className="col-12 col-m-10 col-offset-m-1 text__center">
          <h2 className="text__xl text__bold text__center mg__bottom__l">よくある質問</h2>
          <div className={clsx(styles.inviteFaq, "text__left")}>
            {questions.map((question) => {
              return (
                <dl key={question.id}>
                  <dt className="text__m text__bold" onClick={() => clickFaq(question.id)}>
                    {question.title}
                    <i
                      className={clsx("fas", {
                        "fa-times": selectedFaqIdList.includes(question.id),
                        "fa-plus": !selectedFaqIdList.includes(question.id),
                      })}
                    />
                  </dt>
                  {selectedFaqIdList.includes(question.id) && <dd>{question.body}</dd>}
                </dl>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}
