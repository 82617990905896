import clsx from "clsx";
import Link from "next/link";

import { ImageLegacy } from "@/components/displays";
import { numberFormat } from "@/utils";

import styles from "./PointPlanCompletion.module.scss";

interface PointPlanCompletionProps {
  before: number;
  after: number;
}

export function PointPlanCompletion({ before, after }: PointPlanCompletionProps): React.ReactNode {
  return (
    <div className={styles.container}>
      <div className="row">
        <div className="col-12 col-m-8 col-offset-m-2">
          <div className="text__center">
            <h2 className="text__xl text__bold text___yellow mg__bottom__l">
              ご注文が完了しました
            </h2>
          </div>
          <ImageLegacy
            src="https://asset.basefood.co.jp/images/parts/illust_android_point.png"
            alt="購入"
            aspectRatio="3/2"
          />
          <p className="text__l text__bold text__center mg__top__s mg__bottom__l">
            ポイントパックのご購入
            <br />
            ありがとうございます！
          </p>
          <div className={clsx("text__center pd__top__m pd__bottom__m", styles.block)}>
            <p className="text__l text__bold mg__bottom__m">保有ポイント</p>
            <p>
              <span className="tag gray inline square mini">購入前</span>
              <span className="text__l">{numberFormat(before)}pt</span>
            </p>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="116.702"
              height="62.343"
              viewBox="0 0 116.702 62.343"
              className={styles.triangle}
            >
              <path
                id="パス_2345"
                data-name="パス 2345"
                d="M61.172,2.828a4,4,0,0,1,5.657,0l54.343,54.343A4,4,0,0,1,118.343,64H9.657a4,4,0,0,1-2.828-6.828Z"
                transform="translate(122.351 64) rotate(180)"
                fill="#fac83c"
              ></path>
            </svg>
            <p>
              <span className="tag yellow inline square mini">購入後</span>
              <span className="text__l text__bold">{numberFormat(after)}pt</span>
            </p>
          </div>
          <p className="text__m mg__bottom__m mg__top__l">
            保有ポイントはマイページ上の「保有ポイント欄」で確認できます。
          </p>
        </div>
      </div>
      <div className="text__center">
        <Link href="/mypage" className="btn inline yellow round">
          マイページで確認する
        </Link>
      </div>
    </div>
  );
}
