"use client";

import { useCallback, useState } from "react";

import clsx from "clsx";
import { usePathname, useSearchParams } from "next/navigation";
import { Controller, useFormContext } from "react-hook-form";

import { LoadingOverlay } from "@/components/displays";
import { PlanDiscountNotice } from "@/components/domains/3month";
import { ConfigButton } from "@/components/domains/checkout-form/CheckoutForm/ConfigButton/ConfigButton";
import { PaymentMethodSelector } from "@/components/domains/checkout-form/PaymentMethodSelector";
import { htmlToast } from "@/components/feedbacks";
import { Button, PanelSelector } from "@/components/inputs";
import { useGetPointProduct } from "@/generated/open-api/customer/customer";
import { PaymentMethod } from "@/models/payment/consts";
import { PointProductName, PointProductPlan } from "@/models/pointProduct/type";
import { useParsedGetCustomer, useParsedGetPoints } from "@/queries";
import { useParsedGetSubscription } from "@/queries";
import { fireAmazonPayAction } from "@/utils/amazon";
import { useCount, useOnce } from "@/utils/hooks";

import {
  confirm,
  gotoAmazon,
  gotoAmazonChangePayment,
  gotoAmazonCheckout,
  submit,
  scrollToForm,
} from "./helpers";
import styles from "./PointForm.module.scss";
import { PointPlanCompletion } from "./PointPlanCompletion";
import { PointPlanConfirmation } from "./PointPlanConfirmation";
import { PointPlanValues } from "./schema";
import { useAmazonPay } from "../../amazon";
import { FormSection } from "../../checkout-form/CheckoutForm/FormSection/FormSection";
import { useAppendCreditCardPaymentData } from "../../checkout-form/CheckoutForm/useAppendCreditCardPaymentData";

const options = [
  {
    title: (
      <>
        {PointProductName[PointProductPlan.lightSet]}
        <br />
        ¥20,000で21,000pt購入
      </>
    ),
    value: PointProductPlan.lightSet,
  },
  {
    title: (
      <>
        {PointProductName[PointProductPlan.annualSet]}
        <br />
        ¥66,000で72,600pt購入
      </>
    ),
    value: PointProductPlan.annualSet,
  },
];

type Phase = "form" | "confirmation" | "completion";

export function PointPlanForm(): React.ReactNode {
  const [loadingCount, startLoading, endLoading] = useCount();
  const isLoading = loadingCount > 0;
  const pathname = usePathname();
  const { refetch: refetchGetPoints } = useParsedGetPoints();
  const { appendCreditCardPaymentData } = useAppendCreditCardPaymentData();
  const { data: customer } = useParsedGetCustomer();

  const searchParams = useSearchParams();
  const [phase, setPhase] = useState<Phase>("form");

  const {
    amazonPayEnable,
    amazonPayCheckoutSessionId,
    amazonPayCheckoutData,
    amazonPayCheckoutError,
  } = useAmazonPay();

  const {
    data: { customerDiscountPlan },
  } = useParsedGetSubscription();

  const formContext = useFormContext<PointPlanValues>();

  const { data: currentPlan, isLoading: isGettingPointProduct } = useGetPointProduct({
    name: formContext.watch("planType"),
  });

  const handleChangeAmazonPayPayment = useCallback(async () => {
    await gotoAmazonChangePayment(formContext.getValues(), amazonPayCheckoutSessionId);
  }, [amazonPayCheckoutSessionId, formContext]);

  const handleConfirm = useCallback(async () => {
    if (!currentPlan?.pointProduct) {
      return;
    }

    startLoading();

    let values = formContext.getValues();
    if (values.paymentData.paymentMethod === PaymentMethod.amazon && !amazonPayEnable) {
      return await gotoAmazon(values, pathname);
    }

    if (values.paymentData.paymentMethod === PaymentMethod.credit) {
      try {
        values = await appendCreditCardPaymentData(values, {
          email: customer.email,
          amount: currentPlan.pointProduct.price,
          is_subscription: false,
        });

        // formContextの値を更新
        formContext.setValue("paymentData", values.paymentData);
      } catch (error) {
        if (error instanceof Error) {
          htmlToast.error(error.message);
        }
        endLoading();
        return;
      }
    }

    const isConfirmed = await confirm(values, currentPlan.pointProduct);
    if (isConfirmed) setPhase("confirmation");
    scrollToForm();
    endLoading();
  }, [
    currentPlan?.pointProduct,
    startLoading,
    formContext,
    amazonPayEnable,
    endLoading,
    pathname,
    appendCreditCardPaymentData,
    customer.email,
  ]);

  const [[before, after], setPoints] = useState([0, 0]);

  const executeSubmit = useCallback(async () => {
    if (!currentPlan?.pointProduct) {
      return;
    }

    startLoading();
    try {
      const res = await submit(formContext.getValues(), currentPlan.pointProduct);
      if (res) {
        setPhase("completion");
        setPoints([Number(res.before_point), Number(res.after_point)]);

        // マイページのポイント情報を更新
        await refetchGetPoints();
      }
      scrollToForm();
    } finally {
      endLoading();
    }
  }, [currentPlan?.pointProduct, startLoading, formContext, endLoading, refetchGetPoints]);

  const handleSubmit = useCallback(async () => {
    // amazonPayの場合はAmazon側で注文確定を実行する
    if (amazonPayEnable) {
      if (!currentPlan?.pointProduct) {
        return;
      }

      startLoading();
      try {
        await gotoAmazonCheckout(formContext.getValues(), currentPlan.pointProduct, pathname);
      } finally {
        endLoading();
      }
      return;
    }

    await executeSubmit();
  }, [
    amazonPayEnable,
    executeSubmit,
    currentPlan?.pointProduct,
    startLoading,
    formContext,
    pathname,
    endLoading,
  ]);

  // リセットボタン
  const handleBack = useCallback(() => {
    setPhase("form");
    const values = formContext.getValues();
    if (values.paymentData.paymentMethod === PaymentMethod.credit) {
      // クレジットカードの場合、クレジットカードを記憶しないためリセット
      formContext.setValue("paymentData.stripePaymentMethodId", "");
    }
  }, [formContext]);

  // Amazonから戻ってきたときの処理(エラー時)
  useOnce(async () => {
    if (amazonPayCheckoutError) {
      alert(amazonPayCheckoutError);
      await fireAmazonPayAction(amazonPayCheckoutSessionId, "changeAddress");
    }
  });

  // Amazonから戻ってきたときの処理
  useOnce(async () => {
    if (amazonPayEnable) {
      scrollToForm();
      if (searchParams.get("confirm") === "true") {
        // 注文確定のボタンを押して、Amazon側で注文確定を実行したあとリダイレクトで戻った場合、注文をバックエンドに送り完了画面に遷移
        await executeSubmit();
      }
    }
  }, !isGettingPointProduct);

  return (
    <LoadingOverlay isLoading={isLoading || isGettingPointProduct}>
      {phase === "confirmation" ? (
        <PointPlanConfirmation
          form={formContext.getValues()}
          pointProduct={currentPlan?.pointProduct}
          onClickBack={handleBack}
          onClickSubmit={handleSubmit}
        />
      ) : phase === "completion" ? (
        <PointPlanCompletion before={before} after={after} />
      ) : (
        <form className={styles.checkout}>
          <div className="text__center">
            <h2 className="text__xl text__bold text__center text___yellow mg__bottom__m">
              <span className="wsnr">お得なポイントを</span>
              <wbr />
              <span className="wsnr">購入する</span>
            </h2>
          </div>
          {customerDiscountPlan ? (
            <PlanDiscountNotice />
          ) : (
            <div>
              <FormSection title="プランの選択" titleClassName={styles.xlTitle}>
                <Controller
                  name="planType"
                  render={({ field: { value, onChange } }) => {
                    return (
                      <PanelSelector
                        value={value}
                        onChange={onChange}
                        options={options}
                        labelTwoRows
                      />
                    );
                  }}
                />
              </FormSection>
              {currentPlan?.canPurchase === false && (
                <p className="text__m text__bold text__red text__center__pc">
                  保有できるポイント上限は100,000ptのため、ご購入いただけません。
                </p>
              )}

              <FormSection
                title="支払い方法"
                titleClassName={styles.lTitle}
                subText="すべての取引は安全であり、暗号化されています。"
                action={
                  amazonPayEnable && (
                    <ConfigButton onClick={handleChangeAmazonPayPayment}>変更</ConfigButton>
                  )
                }
              >
                {amazonPayEnable ? (
                  <p className={clsx("text__m", "mg__bottom__s")}>
                    {amazonPayCheckoutData?.paymentPreferences}
                  </p>
                ) : (
                  <Controller
                    name="paymentData"
                    render={({ field: { value, onChange } }) => {
                      return (
                        <PaymentMethodSelector onChangePaymentData={onChange} paymentData={value} />
                      );
                    }}
                  />
                )}
              </FormSection>
              <div className="text__center">
                <Button
                  variants={"yellow"}
                  rounded
                  size={"md"}
                  onClick={handleConfirm}
                  className="text__m mg__bottom__s"
                  type="button"
                  disabled={!currentPlan?.canPurchase}
                >
                  お得なポイントを購入する
                  <i className="fas fa-angle-right"></i>
                </Button>
              </div>
            </div>
          )}
        </form>
      )}
    </LoadingOverlay>
  );
}
