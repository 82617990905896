/**
 * Generated by orval v6.24.0 🍺
 * Do not edit manually.
 * Payse API
 * How to use payse API
 * OpenAPI spec version: 3.0.1
 */
import { useMutation } from "@tanstack/react-query";
import type { MutationFunction, UseMutationOptions } from "@tanstack/react-query";
import type {
  PostPointProductCreate200,
  PostPointProductCreate400,
  PostPointProductCreateBody,
} from ".././schemas";
import { customAxiosInstance } from "../../../configs/customAxiosInstance";
import type { ErrorType } from "../../../configs/customAxiosInstance";
import { customFormUrlEncodedFn } from "../../../configs/formUrlEncoded";

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

/**
 * @summary purchase point product create
 */
export const postPointProductCreate = (
  postPointProductCreateBody: PostPointProductCreateBody,
  options?: SecondParameter<typeof customAxiosInstance>
) => {
  return customAxiosInstance<PostPointProductCreate200>(
    {
      url: `/pointCart/create`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: postPointProductCreateBody,
    },
    options
  );
};

export const getPostPointProductCreateMutationOptions = <
  TError = ErrorType<PostPointProductCreate400>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postPointProductCreate>>,
    TError,
    { data: PostPointProductCreateBody },
    TContext
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postPointProductCreate>>,
  TError,
  { data: PostPointProductCreateBody },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postPointProductCreate>>,
    { data: PostPointProductCreateBody }
  > = (props) => {
    const { data } = props ?? {};

    return postPointProductCreate(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostPointProductCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof postPointProductCreate>>
>;
export type PostPointProductCreateMutationBody = PostPointProductCreateBody;
export type PostPointProductCreateMutationError = ErrorType<PostPointProductCreate400>;

/**
 * @summary purchase point product create
 */
export const usePostPointProductCreate = <
  TError = ErrorType<PostPointProductCreate400>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postPointProductCreate>>,
    TError,
    { data: PostPointProductCreateBody },
    TContext
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}) => {
  const mutationOptions = getPostPointProductCreateMutationOptions(options);

  return useMutation(mutationOptions);
};
